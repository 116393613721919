<template>
  <div class="parents">

    <div class="top">
      <el-input
        class="input"
        v-model="form.customerName"
        placeholder="请输入客户名"
      ></el-input>
      <el-input
        class="input"
        v-model="form.saleName"
        placeholder="请输入销售员名字"
      ></el-input>
      <el-button
        class="leftgap"
        type="primary"
        @click="loadArticles()"
      >查询</el-button>
      <el-button
        class="leftgap"
        type="primary"
        @click="add_edit_user('add', null)"
      >新增</el-button>
      <el-button
        class="leftgap"
        type="primary"
        @click="research()"
      >重置</el-button>
    </div>
    <div class="bottom">
      <el-table
        :data="tabledata"
        stripe
        class="list-table"
        height="100%"
        style="width: 100%"
        size="mini"
      >
        <el-table-column
          type="index"
          min-width="300"
          label="序号"
        ></el-table-column>
        <el-table-column
          prop="companyCode"
          label="编码"
        > </el-table-column>
        <el-table-column
          prop="account"
          label="账号"
        > </el-table-column>
        <el-table-column
          prop="name"
          label="公司名称"
        > </el-table-column>
        <el-table-column
          prop="countryOrArea"
          label="国家/地区"
        > </el-table-column>
        <el-table-column
          prop="area"
          label="大区"
          key="area"
        >
          <template v-slot="scope">
            {{  scope.row.area | Areadata  }}
          </template>
        </el-table-column>
        <el-table-column
          prop="email"
          label="联系邮箱"
        > </el-table-column>
        <el-table-column
          prop="saleName"
          label="销售员"
        > </el-table-column>

        <el-table-column
          width="180"
          label="操作"
          fixed="right"
        >
          <template slot-scope="scope">
            <div>
              <el-tooltip
                content="编辑"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="add_edit_user('edit', scope.row)"
                  circle
                  icon="el-icon-edit"
                  type="primary"
                ></el-button>
              </el-tooltip>
              
              <el-tooltip
                content="查看详情"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="showdetaile(scope.row)"
                  circle
                  icon="el-icon-search"
                  type="primary"
                ></el-button>
              </el-tooltip>

              <el-tooltip
                content="删除"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="deleteToBuser(scope.row)"
                  circle
                  icon="el-icon-delete"
                  type="primary"
                ></el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="prev, pager, next"
        background
        :total="totalCount"
        :page-size="form.size"
        :current-page.sync="form.pages"
        @current-change="onCurrentChange"
      />

    </div>

    <!-- 编辑、添加 -->
    <el-dialog
      class="form-panel"
      :title="panelTitle"
      :visible.sync="showadd_edit"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="800px"
      @closed="CloseFormPanel"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="编码"
          prop="companyCode"
        >
          <el-input v-model="ruleForm.companyCode"></el-input>
        </el-form-item>
        <el-form-item
          label="账号"
          prop="account"
        >
          <el-input v-model="ruleForm.account"></el-input>
        </el-form-item>
        <el-form-item
          label="公司名称"
          prop="name"
        >
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item
          label="国家/地区"
          prop="countryOrArea"
        >
          <el-input v-model="ruleForm.countryOrArea"></el-input>
        </el-form-item>
        <el-form-item
          label="大区"
          prop="area"
        >
          <el-select v-model="ruleForm.area">
            <el-option
              v-for="item in areaoptions"
              :key="item.value"
              :label="item.label"
              :value="parseInt(item.value)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="公司网址"
          prop="companyWebsite"
        >
          <el-input v-model="ruleForm.companyWebsite"></el-input>
        </el-form-item>
        <el-form-item
          label="公司电话"
          prop="phoneNumber"
        >
          <el-input v-model="ruleForm.phoneNumber"></el-input>
        </el-form-item>
        <el-form-item
          label="联系邮箱"
          prop="email"
        >
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item
          label="客户公司邮箱"
          prop="companyEmail"
        >
          <el-input v-model="ruleForm.companyEmail"></el-input>
        </el-form-item>
        <el-form-item
          label="其他联系方式"
          prop="otherContactInformation"
        >
          <el-input v-model="ruleForm.otherContactInformation"></el-input>
        </el-form-item>
        <el-form-item
          label="销售员"
          prop="saleName"
        >
          <el-input v-model="ruleForm.saleName"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            size="small"
            type="info"
            @click="CloseFormPanel"
          >取消</el-button>
          <el-button
            size="small"
            type="primary"
            @click="submitEditInfo(ruleForm)"
          >确定</el-button>
        </el-form-item>

      </el-form>

    </el-dialog>

    <!-- 编辑、添加 -->
    <el-dialog
      class="form-panel"
      :visible.sync="ifshowDetaile"
      width="800px"
    >
      <el-descriptions
        title="客户详情"
        :column="1"
        border
      >
        <el-descriptions-item label="编码 ">{{CustomerDatile.companyCode}}</el-descriptions-item>
        <el-descriptions-item label="账号 ">{{CustomerDatile.account}}</el-descriptions-item>
        <el-descriptions-item label="公司名称 ">{{CustomerDatile.name}}</el-descriptions-item>
        <el-descriptions-item label="国家/地区 ">{{CustomerDatile.countryOrArea}}</el-descriptions-item>
        <el-descriptions-item label="公司网址 ">{{CustomerDatile.companyWebsite}}</el-descriptions-item>
        <el-descriptions-item label="公司电话 ">{{CustomerDatile. phoneNumber}}</el-descriptions-item>
        <el-descriptions-item label="联系邮箱 ">{{CustomerDatile.email}}</el-descriptions-item>
        <el-descriptions-item label="客户公司邮箱 ">{{CustomerDatile.companyEmai}}</el-descriptions-item>
        <el-descriptions-item label="其他联系方式 ">{{CustomerDatile.otherContactInformation}}</el-descriptions-item>
        <el-descriptions-item label="销售员 ">{{CustomerDatile.saleName}}</el-descriptions-item>

      </el-descriptions>

    </el-dialog>
  </div>
</template>

<script>
import {
  getBusinessCustomerAccountList,
  getAccountDetail,
  editTobAccount,
  addTobAccount,
  deleteBusinessAccount
} from "@/api/jurisdiction";
import { areaoptions } from "@/options/index";

export default {
  filters: {
    Areadata(value) {
      return areaoptions.find((i) => value == i.value)?.label;
    },
  },
  data() {
    return {
      rules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        area: [{ required: true, message: "请选择地区", trigger: "blur" }],
        countryOrArea: [
          { required: true, message: "请输入国家/大区", trigger: "blur" },
        ],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        name: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
        saleName: [{ required: true, message: "请输入销售", trigger: "blur" }],
      },

      areaoptions,
      selectdept: "",
      postIdsList: [],
      roleIdsList: [],
      ruleForm: {
        companyCode: null,
        account: null,
        name: null,
        countryOrArea: null,
        area: null,
        companyWebsite: null,
        phoneNumber: null,
        email: null,
        companyEmail: null,
        otherContactInformation: null,
        saleName: null,
      },

      delectalartshow: false,
      panelTitle: null,
      form: {
        customerName: null,
        saleName: null,
        size: 20,
        page: 1,
        deptId: 1,
      },
      treedata: [],
      totalCount: 0,
      tabledata: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      showadd_edit: false,
      ifshowDetaile: false,
      CustomerDatile: {},
    };
  },
  mounted() {
    this.loadArticles(1);
  },
  methods: {
    deleteToBuser(row){
      this.$confirm("将删除该客户，是否继续", "警告", {
        type: "warning",
        confirmButtonText: "确定",
        closeOnClickModal: false, // 点击model背景层不完成MessageBox
      }).then(() => {
        deleteBusinessAccount({ id: row.id }).then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            this.loadArticles();
            this.$message({
              message: "成功",
              type: "success",
            });
          }
        });
      });
    },
    async showdetaile(row) {
      try {
        let res;
        res = await getAccountDetail({ id: row.id });
        if (res.data.code === 200) {
          this.ifshowDetaile = true;
          this.CustomerDatile = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },
    async submitEditInfo() {
      try {
        let res;
        if (this.ruleForm.id != null) {
          res = await editTobAccount(this.ruleForm);
        } else {
          res = await addTobAccount(this.ruleForm);
        }
        if (res.data.code === 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.ruleForm = {};
        this.research();
        this.showadd_edit = false;
      }
    },

    add_edit_user(type, row) {
      console.log(row);
      this.ruleForm = {};
      if (type === "add") {
        this.panelTitle = "新增";
      } else if (type === "edit") {
        this.panelTitle = "编辑";
        this.ruleForm = row;
      }
      this.showadd_edit = true;
    },

    CloseFormPanel() {
      this.showadd_edit = false;
      this.ruleForm = {};
    },

    research() {
      this.form.customerName = null;
      this.form.saleName = null;
      this.loadArticles();
    },
    showdelect(row, column, event) {
      this.delectalartshow = true;
    },
    async delectdept() {},

    onCurrentChange(page) {
      this.loadArticles(page);
    },
    async loadArticles(page = 1, deptId = null) {
      this.form.page = page;
      this.form.deptId = deptId;
      // 展示加载中 loading
      this.loading = true;
      try {
        const res = await getBusinessCustomerAccountList(this.form);
        if (res.data.code === 200) {
          this.tabledata = res.data.data.record;
          this.totalCount = res.data.data.total;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
.parents {
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .top {
    padding-top: 10px;
    padding-bottom: 10px;
    border-width: 0px, 0px, 0px, 0px;
    border-bottom-color: #6f716a;
    border-bottom-style: solid;
    .input {
      height: 20%;
      width: 200px;
      margin-right: 10px;
    }
  }
  .bottom {
    height: 80%;
  }
}

.edit-form {
  display: flex;
  flex-wrap: wrap;
  .edit-form-text {
    width: 260px;
  }
  .edit-form-select {
    width: 260px;
  }
  .edit-form-switch {
    width: 280px;
  }
  .deptInfos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px dotted #ddd;
  }
  .regulation-btn {
    z-index: 1;
    span {
      cursor: pointer;
      font-size: 20px;
      color: #20a0ff;
      &:first-child {
        margin-left: -50px;
        margin-right: 10px;
      }
    }
  }
}
</style>
